@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi-Variable";
  src: url(/src/assets/fonts/Satoshi-Variable.ttf);
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "clash";
  src: url(/src/assets/fonts/ClashDisplay-Variable.ttf);
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

html body {
  margin: auto;
  scroll-behavior: smooth;
  padding: 0;
  overflow-x: hidden;
  background: rgba(23, 23, 23);
  font-family: Satoshi-Variable;
}

.containerr {
  max-width: 1440px;
  margin: auto;
}

#navbar {
  transition: 0.7s ease-in-out;
}

#navbar.active {
  top: -11em;
  padding: 2.5em 0 0;
  transition: 0.7s ease-in-out;
}

.menu-icon {
  transition: 0.7s ease-in-out;
}

input {
  -webkit-text-size-adjust: 100%;
}


.resume {
  animation: resume 1.3s ease-in-out infinite;
  transition: all 0.15s ease-in-out;
}

@keyframes resume {
  0% {
    translate: 0;
  }
  50% {
    translate: 0.5rem;
  }
  75% {
    translate: 0;
  }
  100% {
    translate: 0;
  }
}
